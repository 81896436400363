import React, { Component } from "react";

export const isValidEmailAddress = (string: string) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(string)) {
    return "Invalid email address";
  }
  return null;
};

export class EmailSubmitBox extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      value: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      value: e.target.value,
      errorMessage: "",
    });
  }
  handleSubmit() {
    const isError = isValidEmailAddress(this.props.value);

    if (isError || !this.props.value) {
      this.setState({
        errorMessage: "This is not a valid email address!",
      });
    }
  }

  render() {
    const { value, handleChange, emailValidationMessage} = this.props;

    const { errorMessage } = this.state;

    return (
      <div>
        <input
          autoComplete="email"
          value={value || ""}
          placeholder="Email Address"
          onChange={(e) => {
            this.setState({ errorMessage: null });
            handleChange(e.target.value);
          }}
          type="text"
        />
        <button
          style={{ margin: "0 0 0 8px" }}
          onClick={(e) => {
            e.preventDefault()
            this.handleSubmit();
            this.props.handleSubmit();
          }}
        >
          Submit
        </button>
        {errorMessage && <div style={{ color: "red" }}>{emailValidationMessage}</div>}
      </div>
    );
  }
}