import React, { Component } from "react";
import "./Test.css";
import engagementPicUrl from "./static/sterv-nati-engagement-photo.jpg";
import { EmailSubmitBox, isValidEmailAddress } from "./EmailSubmitBox";
import { options1, options2, options3 } from "./menuOptions";
import { CheckboxGroup } from "./CheckboxGroup";
import { AddressSubmit } from "./AddressSubmit";
import { saveRSVP } from "./firebase";

// const images = [
//   "https://images.genius.com/b82b8f57f581e62776896064454dc40a.300x300x1.jpg",
//   "https://www.music-bazaar.com/album-images/vol18/846/846227/2703449-big/Cassie-cover.jpg",
//   "https://www.udiscovermusic.com/wp-content/uploads/2020/07/Rick-Ross-Teflon-Don.jpg",
//   "https://www.udiscovermusic.com/wp-content/uploads/2020/07/Rick-Ross-Teflon-Don.jpg",
// ];

interface AppState {
  rsvp: string | null;
  rsvpName: string | null;
  guestName: string | null;
  guest: string | null;
  traveling: string | null;
  travelingPlace: string | null;
  hasSubmitted: boolean;
  email: string | null;
  rsvpComplete: boolean;
  emailValidationMessage: string | null;
  docId: string | null;
}

const testAddress = false;
const generateMessage = (state: AppState) => {
  if (state.rsvp === "yes" || testAddress)
    return <AddressSubmit docId={state.docId} />;
  if (state.rsvp === "no")
    return "Sorry you can't make it! Thanks for letting us know.";
};

const allValuesEnter = (state: AppState) => {
  if (state.emailValidationMessage) return false;
  if (!state.rsvp || !state.rsvpName || !state.guest || !state.traveling)
    return false;

  const travelMissing =
    !state.traveling || (state.traveling === "yes" && !state.travelingPlace);
  const guestMissing =
    !state.guest || (state.guest === "yes" && !state.guestName);

  if (travelMissing || guestMissing) return false;

  return true;
};

const sendValuesToDb = async (state: AppState): Promise<string> => {
  const payload = {
    rsvp: state.rsvp,
    rsvpName: state.rsvpName,
    guestName: state.guestName,
    guest: state.guest,
    traveling: state.traveling,
    travelingPlace: state.travelingPlace,
    email: state.email,
  };

  console.log(payload);

  return saveRSVP(payload);
};

class App extends Component<any, AppState> {
  constructor(props: any) {
    super(props);

    this.state = {
      rsvp: null,
      rsvpName: null,
      guest: null,
      guestName: null,
      traveling: null,
      travelingPlace: null,
      hasSubmitted: false,
      email: null,
      rsvpComplete: false || testAddress,
      emailValidationMessage: "Invalid email address",
      docId: null,
    };

    this.updateValue = this.updateValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    if (allValuesEnter(this.state)) {
      const docId = await sendValuesToDb(this.state);

      this.setState({
        rsvpComplete: true,
        docId,
      });
    } else {
      this.setState({
        hasSubmitted: true,
      });
    }
  }

  updateValue(key: keyof AppState, value: string | null) {
    let emailValidationMessage = this.state.emailValidationMessage;

    if (key === "email" && value) {
      emailValidationMessage = isValidEmailAddress(value);
    }

    const payload: AppState = {
      ...this.state,
      emailValidationMessage,
      [key]: value,
    };

    this.setState(payload);
  }

  render() {
    return (
      <div
        // className="bg-cover flex justify-center items-center bg-center"
        className="wrapper"
        // style={{ backgroundImage: `url(https://i.imgur.com/INZkyuF.gif)` }}
        // style={{ backgroundImage: `url(${engagementGif})` }}
      >
        <div className="window z-10">
          <div className="title-bar">
            <div className="title-bar-text">Save the Date</div>
            <div className="title-bar-controls">
              <button aria-label="Minimize"></button>
              <button aria-label="Maximize"></button>
              <button aria-label="Close"></button>
            </div>
          </div>
          {/*
           */}
          <div className="window-body">
            <div className="flex items-start">
              <img
                alt=""
                src={engagementPicUrl}
                // src="https://www.laweekly.com/wp-content/uploads/2019/05/tom_anderson_myspace_smjpg_jpeg_image_230x228_pixels.png"
                width="96"
                className="mt-1"
              />
              <div className="ml-2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1 className="text-xl font-bold">
                    <span>{`Nati + Sterv's Wedding`}</span>
                    <img
                      alt=""
                      src="https://66.media.tumblr.com/14f9a65f9733eac78600ea51b979cfd3/tumblr_ng4sf61TfA1qcwgxao1_500.gifv"
                      // width="24"
                      style={{
                        display: "initial",
                        width: "24px",
                        height: "24px",
                      }}
                      className="ml-1 -mt-1"
                    />
                  </h1>
                </div>
                <p className="text-sm">Save the Date!</p>
                <p className="text-sm mt-1">
                  Saturday, January 22nd 2022 - Miami, FL
                </p>
                <p className="text-sm mt-1">
                  More details to come in the future. Please RSVP below!
                </p>
              </div>
            </div>
            {this.state.rsvpComplete && (
              <fieldset style={{ margin: "1em" }}>
                <div
                  style={{
                    padding: "1em",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  {generateMessage(this.state)}
                </div>
              </fieldset>
            )}
            <form>
              {!this.state.rsvpComplete && (
                <fieldset className="mt-4" style={{ padding: "1em" }}>
                  <div className="grid grid-cols-3 gap-4">
                    <CheckboxGroup
                      displayTextBox
                      nameLabel="Name"
                      label="RSVP:"
                      options={options1}
                      selectedValue={this.state.rsvp}
                      secondValue={this.state.rsvpName}
                      handleChange={(value: string) =>
                        this.updateValue("rsvp", value)
                      }
                      handleSecondChange={(value: string) => {
                        this.updateValue("rsvpName", value);
                      }}
                      displayError={
                        !this.state.hasSubmitted
                          ? false
                          : !this.state.rsvp || !this.state.rsvpName
                      }
                    />
                    <CheckboxGroup
                      nameLabel="Guest name"
                      label="Guest:"
                      options={options2}
                      selectedValue={this.state.guest}
                      secondValue={this.state.guestName}
                      handleChange={(value: string) =>
                        this.updateValue("guest", value)
                      }
                      handleSecondChange={(value: string) =>
                        this.updateValue("guestName", value)
                      }
                      displayError={
                        !this.state.hasSubmitted
                          ? false
                          : !this.state.guest ||
                            (this.state.guest === "yes" &&
                              !this.state.guestName)
                      }
                    />
                    <CheckboxGroup
                      nameLabel="From where?"
                      label="Traveling:"
                      options={options3}
                      secondValue={this.state.travelingPlace}
                      selectedValue={this.state.traveling}
                      handleChange={(value: string) =>
                        this.updateValue("traveling", value)
                      }
                      handleSecondChange={(value: string) =>
                        this.updateValue("travelingPlace", value)
                      }
                      displayError={
                        !this.state.hasSubmitted
                          ? false
                          : !this.state.traveling ||
                            (this.state.traveling === "yes" &&
                              !this.state.travelingPlace)
                      }
                    />
                  </div>
                </fieldset>
              )}

              {!this.state.rsvpComplete && (
                <div
                  // className="mb-8 px-6"
                  className="mx-auto my-5"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label style={{ padding: "0  0 .25em" }}>
                    Please enter your email to complete your RSVP:
                  </label>
                  <EmailSubmitBox
                    value={this.state.email}
                    handleChange={(value: string) =>
                      this.updateValue("email", value)
                    }
                    handleSubmit={this.handleSubmit}
                    emailValidationMessage={this.state.emailValidationMessage}
                    hasSubmitted={this.state.hasSubmitted}
                  />
                </div>
              )}
            </form>

            {/* <fieldset>
              <div className="grid grid-cols-4 gap-2">
                {images.map((src) => (
                  <img alt="" src={src} />
                ))}
              </div>
            </fieldset> */}
            {/* <img
            src="https://miro.medium.com/max/918/0*rpB-jsFlnH9ZbzII"
            width="100"
            className="mx-auto my-2"
          /> */}
          </div>
        </div>
      </div>
    );
  }
}
export default App;
