export const options1 = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
  // {
  //   label: "Maybe",
  //   value: "maybe",
  // },
];

export const options2 = [
  {
    label: "+1",
    value: "yes",
  },
  {
    label: "0",
    value: "no",
  },
  // {
  //   label: "N/A",
  //   value: "not applicable",
  // },
];

export const options3 = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
  // {
  //   label: "N/A",
  //   value: "not applicable",
  // },
];
