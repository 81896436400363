import React, { Component } from "react";
import { AddressData, saveAddress } from "./firebase";

const didSubmitEverything = (thingState: any) => {
  const { recipient, primary_line, city, state, zip_code } = thingState;

  if (!recipient || !primary_line || !city || !state || !zip_code) {
    return false;
  }
  return true;
};

export class AddressSubmit extends Component<
  {
    docId: string | null;
  },
  AddressData & { didSubmit: boolean; didComplete: boolean }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      recipient: "",
      primary_line: "",
      secondary_line: "",
      city: "",
      state: "",
      zip_code: "",
      didSubmit: false,
      didComplete: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(key: string, value: string) {
    // @ts-ignore
    this.setState({
      [key]: value,
    });
  }

  handleSubmit() {
    if (didSubmitEverything(this.state)) {
      this.setState({
        didSubmit: true,
        didComplete: true,
      });

      if (this.props.docId) {
        let nextData : any = Object.assign({}, this.state);
        delete nextData["didSubmit"]
        delete nextData["didComplete"]

        saveAddress(this.props.docId, nextData);
      }
    } else {
      this.setState({
        didSubmit: true,
      });
    }
  }

  render() {
    const handleChange = this.handleChange;

    const { didSubmit, didComplete } = this.state;

    if (didComplete)
      return (
        <div>You have successfully RSVP'd. See you next year in Miami!</div>
      );
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ margin: "0 0 .5em 0" }}>
          Where should we send your invitation?
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <input
              autoComplete="name"
              className="address-field"
              value={this.state.recipient}
              placeholder="Recipient"
              onChange={(e) => {
                handleChange("recipient", e.target.value);
              }}
              type="text"
            />
            {didSubmit && !this.state.recipient && <span>Required!</span>}
            <input
              autoComplete="address-line1"
              className="address-field"
              value={this.state.primary_line}
              placeholder="Primary Line"
              onChange={(e) => {
                handleChange("primary_line", e.target.value);
              }}
              type="text"
            />
            {didSubmit && !this.state.primary_line && <span>Required!</span>}
            <input
              autoComplete="address-line2"
              className="address-field"
              value={this.state.secondary_line}
              placeholder="Secondary Line"
              onChange={(e) => {
                handleChange("secondary_line", e.target.value);
              }}
              type="text"
            />
          </div>
          <div>
            <input
              autoComplete="address-level2"
              className="address-field"
              value={this.state.city}
              placeholder="City"
              onChange={(e) => {
                handleChange("city", e.target.value);
              }}
              type="text"
            />
            {didSubmit && !this.state.city && <span>Required!</span>}
            <input
              autoComplete="address-level1"
              className="address-field"
              value={this.state.state}
              placeholder="State"
              onChange={(e) => {
                handleChange("state", e.target.value);
              }}
              type="text"
            />
            {didSubmit && !this.state.state && <span>Required!</span>}
            <input
              autoComplete="postal-code"
              className="address-field"
              value={this.state.zip_code}
              placeholder="Zip Code"
              onChange={(e) => {
                handleChange("zip_code", e.target.value);
              }}
              type="text"
            />
            {didSubmit && !this.state.zip_code && <span>Required!</span>}
          </div>
        </div>
        <span>
          <button
            style={{ margin: ".5em 0 0" }}
            onClick={() => {
              this.handleSubmit();
            }}
          >
            Submit
          </button>
        </span>
        {didSubmit && didSubmitEverything(this.state) && (
          <div style={{ color: "red" }}>
            {"Please fill out the remainding fields"}
          </div>
        )}
      </div>
    );
  }
}
