import firebase from "firebase";
// Required for side-effects
require("firebase/firestore");

// Initialize Cloud Firestore through Firebase

const firebaseConfig = {
  apiKey: "AIzaSyAqKy_rnmTcOCMmcZel626dHbojXHOItPQ",
  authDomain: "wedding-9b227.firebaseapp.com",
  projectId: "wedding-9b227",
  storageBucket: "wedding-9b227.appspot.com",
  messagingSenderId: "55173662235",
  appId: "1:55173662235:web:0c29d4d6cfcf9c9bf35abe",
  measurementId: "G-8W6DFVSCLR",
};

firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

interface Payload {
  rsvp: string | null;
  rsvpName: string | null;
  guestName: string | null;
  guest: string | null;
  traveling: string | null;
  travelingPlace: string | null;
  email: string | null;
}

firebase.analytics();

export const saveRSVP = async (data: Payload) => {
  var docRef = db.collection("rsvps").doc();

  await docRef.set(data);
  return docRef.id;
};

export interface AddressData {
  recipient: string;
  primary_line: string;
  secondary_line: string;
  city: string;
  state: string;
  zip_code: string;
}

export const saveAddress = async (id: string, data: AddressData) => {
  var docRef = db.collection("rsvps").doc(id);

  await docRef.update({
    address: data,
  });
};
