import React, { Component } from "react";

type Options = { label: string; value: string }[];

const generateErrorMessage = (value: string | null) => "Required.";

export class CheckboxGroup extends Component<{
  nameLabel: string;
  label: string;
  options: Options;
  selectedValue: string | null;
  secondValue: string | null;
  handleChange: (e: any) => void;
  handleSecondChange: (e: any) => void;
  displayError: boolean;
  displayTextBox?: boolean;
}> {
  render() {
    const {
      displayError,
      handleChange,
      handleSecondChange,
      selectedValue,
      options,
      label,
      nameLabel,
      secondValue,
      displayTextBox,
    } = this.props;

    const elements = options.map((option) => {
      const isSelected = option.value === selectedValue;
      const id = `${option.value}_${label}`;

      return (
        <div key={id} className="field-row">
          <input
            id={id}
            type="checkbox"
            checked={isSelected}
            onChange={() => handleChange(option.value)}
          />
          <label htmlFor={id}>{option.label}</label>
        </div>
      );
    });

    return (
      <div>
        <label className="font-bold mb-1">{label}</label>
        {elements}
        {
          <input
            autoComplete={label === "Traveling:"? "address-level1" : label !== "Guest:"? "name" : ""}
            className="elementToFadeInAndOut"
            onChange={(e) => handleSecondChange(e.target.value)}
            value={secondValue || ""}
            style={{
              opacity: (displayTextBox && selectedValue) || selectedValue === "yes" ? 1 : 0,
              margin: "5px 0 0",
            }}
            placeholder={nameLabel}
            type="text"
          />
        }
        {displayError && (
          <div style={{ color: "red" }}>
            {generateErrorMessage(selectedValue)}
          </div>
        )}
      </div>
    );
  }
}
